<template>
  <!-- 发货 -->
  <div class="wrapper">
    <!-- 普通订单 -->
    <el-form ref="form" :model="formData" label-width="160px">
      <!-- <el-form-item label="快递公司" prop="deliveryCompany" :rules="rules">
        <SelectDict v-model="formData.deliveryCompany" dictCode="delivery:company"></SelectDict>
      </el-form-item> -->
      <el-form-item label="快递单号" prop="deliveryNo" :rules="rules">
        <el-input style="width:500px;" v-model="formData.deliveryNo" size="small" placeholder="请输入"></el-input>
      </el-form-item>
<!--      <el-form-item label="监管锁">-->
<!--        <el-input style="width:500px;" v-model="formData.lockCode" size="small" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="设备串号" prop="serialNo">
        <el-input style="width:500px;" v-model="formData.serialNo" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="商品采购价" prop="costPrice" :rules="rules">
        <el-input-number style="width:150px;" v-model="formData.costPrice" :min="0" size="small"></el-input-number>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" style="width:500px;" v-model="formData.remark" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注图片" prop="deliveryImageList" :rules="rules">
        <!-- <FileUpload :name.sync="formData.deliveryImage" :url="formData.deliveryImageUrl"></FileUpload> -->
         <FileUpload :isMulti="true" :name.sync="formData.deliveryImageList"></FileUpload>
      </el-form-item>
<!--      <el-form-item label="同时发送线上虚拟权益">-->
<!--        <SelectBenefit v-model="formData.benefitPackageCode"></SelectBenefit>-->
<!--        <el-checkbox class="check-benefit" style="margin-left:10px;" v-model="is_benefit"></el-checkbox>-->
<!--      </el-form-item>-->
      <el-form-item label="">
        <el-button type="" size="small" @click="closePage">取消</el-button>
        <el-button type="primary" size="small" @click="save">提交</el-button>
      </el-form-item>
    </el-form>
    <!-- 门店订单 -->
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { dict_order_type } from '@/enum/dict.js'
import { handleDelieryAPI } from './api'
import FileUpload from '@/components/file-upload/index-send.vue'
import SelectDict from '@/views/components/select-dict.vue'
import SelectBenefit from '@/views/components/select-benefit.vue'
export default {
  name: 'Delivery',
  components: { FileUpload },
  data() {
    return {
      activeName: '1',
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        orderNo: this.$route.params.id,
        deliveryCompany: '',
        deliveryNo: '',
        serialNo: '',
        costPrice: '',
        deliveryImageList: null,
        deliveryImageUrlList: null,
        remark: '',
        lockCode: '',
        benefitPackageCode: ''
      },
      dict_order_type,
      is_benefit: false
    }
  },
  watch: {
    is_benefit: {
      handler(newV, oldV) {
        if (!this.is_benefit) {
          this.formData.benefitPackageCode = ''
        }
      }
    }
  },
  mounted() {},

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.is_benefit && !this.formData.benefitPackageCode) {
            return this.$message.warning('请先选择线上虚拟权益')
          }
          if (!this.is_benefit) {
            this.formData.benefitPackageCode = ''
          }
          handleDelieryAPI(this.formData).then(() => {
            this.$message.success('发货成功')
            setTimeout(() => {
              this.closePage()
            }, 1000)
          })
        }
      })
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList()
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList()
      }
    },
    closePage() {
      this.emitAwindow()
      window.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .check-benefit {
    .el-checkbox__inner {
      transform: scale(1.4);
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }

  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }

  .goods-sku,
  .baseInfo-2 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}
</style>
